<template>
  <div>
    <button
      class="btn btn-default btn-sm mt-4 px-5"
      @click="this.$router.go(-1)"
    >
      Back
    </button>
    <div class="card shadow-lg bg-gradient-secondary w-100 border-0 mb-0">
      <div class="card-body px-lg-5 py-lg-3 row">
        <div class="col-md-12">
          <h2 class>Carousel Information</h2>
          <hr class="my-0 mb-3" />
          <div class="row">
            <div class="form-group col-md-6">
              <label class="form-control-label">Title</label>
              <input
                class="form-control form-control-alternative"
                placeholder="Add Content Title"
                type="text"
                v-model="post_data.title"
              />
              <label
                v-for="error in errors.title"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
            <div class="col-md-6"></div>
          </div>

          <div class="row">
            <button @click="update_advert" class="ml-3 btn btn-default btn-md">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { alert } from "../../utils/alertUtils";
export default {
  data() {
    return {
      post_data: {},
      errors: {
        description: [],
      },
    };
  },
  methods: {
    update_advert: function () {
      const url = `${process.env.VUE_APP_BACKEND_URL}/api/posts/${this.$route.params.uuid}`;

      axios
        .put(url, this.post_data, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          alert.success_center("Advert has been updated successfully");
          this.post_data = {};
          this.$router.push({
            name: "CarouselDetail",
            params: { uuid: res.data.uuid },
          });
        })
        .catch((err) => {
          if (err.response.status === 400) {
            alert.error("please check if all fields are filled correctly");
            this.errors = err.response.data;
          } else {
            alert.error(
              "an error occured in updating advert, if the error persist contact the owner of this site."
            );
          }
          console.log(err.response);
        });
    },
    get_advert: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/posts/${this.$route.params.uuid}`;

      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          console.log(res.data);
          this.post_data = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.get_advert();
  },
};
</script>